.nav-item {
    font-size: 16px;
}

html {
    font-size: 14px;
}

/* Make none fullscreen modal have the close icon inside the modal instead of
 * outside.
 */
.ui.modal > .close {
    top: 1.0535rem;
    right: 1rem;
    color: rgba(0, 0, 0, 0.87);
}

a:hover {
    color: unset;
    text-decoration: unset;
}

.ui.disabled.input > input:disabled {
    opacity: 1 !important; /* this will get the 0.45 opacity twice otherwise and thus become unreadable */
}

.ui.modals {
    z-index: 1002 !important;
}

/*
 * Normally we have left buttons and right buttons using a RightDivider from
 * spider, but that depends on Modal.Actions being flex.
 */
.ui.modal > .actions {
    display: flex;
}

/**
 * Fix for showing a loader inside a modal. Took some Googling to make it
 * work...should be fixed in newer Semantic-UI versions.
 */
.ui.dimmer .ui.modal .ui.loader:before {
  border-color: rgba(0,0,0,.1);
}

.ui.dimmer .ui.modal .ui.loader:after {
  border-color: #767676 transparent transparent;
}
